import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SpinnerService } from '../services/spinner/spinner.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
              private spinnerService: SpinnerService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.show();

    req = req.clone({
      withCredentials: true
    });

    if (!req.headers.has('Content-Type')) {
      req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
    }

    if (req.headers.get('Content-type') === 'multipart/form-data') {
      req = req.clone({headers: req.headers.delete('Content-Type')});
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinnerService.hide();
          if (event.status === 401) {
            this.authenticationService.forceLogout();
          }
        }
      }, (error) => {
        if (error.status === 401) {
          // Controllo temporaneo per gestire in modo diverso il 401 dovuto alla sessione scaduta
          // e il 401 per le credenziali sbagliate (nel primo caso chiuso anche tutto le dialog)
          const isLoginPage = error.error.message === 'BadCredentialsException';
          if (isLoginPage) {
            this.authenticationService.forceLogout();
          } else {
            this.authenticationService.forceLogoutCloseDialog();
          }
        }
        this.spinnerService.hide();
        return throwError(error);
      })
    );
  }
}
