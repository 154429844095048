import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { MatSnackBarComponent } from '../../backoffice/components/mat-snack-bar/mat-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {
  constructor(public snackBar: MatSnackBar) {
  }

  openSnackBar(snackMessage: string, snackType: 'S' | 'W' | 'E') {
    this.snackBar.openFromComponent(MatSnackBarComponent, {
      data: {
        message: snackMessage,
        type: snackType
      },
      duration: snackType === 'E' ? 0 : 4000,
      panelClass: snackType === 'S' ? 'snackbar-container-s' : snackType === 'W' ? 'snackbar-container-w' : 'snackbar-container-e'
    });
  }

  closeSnackBar() {
    this.snackBar.dismiss();
  }
}
