import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { DeviceGuard } from './helper/Device.guard';

const routes: Routes = [
  {path: '', component: AppComponent, pathMatch: 'full', canActivate: [DeviceGuard]},
  {path: 'portale-accessi', loadChildren: () => import('./portale-accessi/portale-accessi.module').then(m => m.PortaleAccessiModule)},
  {path: 'app', loadChildren: () => import('./backoffice/backoffice.module').then(m => m.BackofficeModule)},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
