import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';
import { RuoloUtenteEnum } from '../../../../model/enum.model';

@Injectable()
export class OrdineServizioViewGuard implements CanActivate {
  constructor(private router: Router, protected authService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot) {
    if (this.authService.currentUser()
      && (this.authService.currentUser().ruoli.find(r => r.codice === RuoloUtenteEnum.ORDINE_SERVIZIO_VISUALIZZATORE)
        || this.authService.currentUser().ruoli.find(r => r.codice === RuoloUtenteEnum.ADMIN))) {
      return true;
    }

    return this.router.parseUrl('/app/login');
  }
}
