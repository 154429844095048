import { AppConfig } from '../../app-config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from '../spinner/spinner.service';
import { AlertsService } from '../alerts/alerts.service';
import { throwError } from 'rxjs';

@Injectable()
export class HttpUtilService {
  APP_URL = AppConfig.settings.restServer.baseUrl;

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private alert: AlertsService
  ) {
  }

  getById<T>(path, id) {
    return this.http.get<T>(this.APP_URL + path + id, {observe: 'response'})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  create<T>(path, resource) {
    return this.http.post<T>(this.APP_URL + path, resource, {observe: 'response'})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  update<T>(path, id, resource) {
    return this.http.patch<T>(this.APP_URL + path + id, resource, {observe: 'response'})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  delete<T>(path, id) {
    return this.http.delete<T>(this.APP_URL + path + id, {observe: 'response'})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  getCall<T>(path, params?) {
    return this.http.get<T>(this.APP_URL + path, {observe: 'response', params})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  postCall<T>(path, body, params?) {
    return this.http.post<T>(this.APP_URL + path, body, {observe: 'response', params})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  putCall<T>(path, body, params?) {
    return this.http.put<T>(this.APP_URL + path, body, {observe: 'response', params})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  postCallDownload<T>(path, body, accept?, params?, responseType?) {
    const headers = new HttpHeaders({
      accept
    });
    return this.http.post<T>(this.APP_URL + path, body, {observe: 'response', headers, params, responseType})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  postCallUpload<T>(path, body) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post<T>(this.APP_URL + path, body, {observe: 'response', headers})
      .pipe(map(response => response.body),
        catchError(err => this.handleError(err)));
  }

  protected handleError(error: HttpErrorResponse) {
    console.log('error', error);
    this.spinnerService.hide();
    let message = 'Qualcosa è andato storto.';

    if (error.error && error.error.message && error.error.message !== '') {
      message = error.error.message;
      console.error('An error occurred:', error.error.message);
    }

    this.alert.openSnackBar(message, 'E');
    return throwError('error');
  }

}

export interface Pageable {
  page: string;
  size: string;
  sort: string;
}
