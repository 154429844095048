import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, protected authService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot) {
    if (this.authService.currentUser()) {
      // se l'utente loggato non ha flagInterno lo reindirizzo sul portalino
      if (!this.authService.currentUser().flagInterno
        && next.pathFromRoot.length > 0
        && next.pathFromRoot[1].url[0].path.indexOf('app') > -1) {
        return this.router.parseUrl('/portale-accessi/inserimento-accesso');
      }
      // se l'utente loggato ha flagInterno e atterra sul portalino lo reindirizzo su app
      if (this.authService.currentUser().flagInterno
        && next.pathFromRoot.length > 0
        && next.pathFromRoot[1].url[0].path.indexOf('portale-accessi') > -1) {
        return this.router.parseUrl('/app');
      }

      return true;
    }

    if (next.pathFromRoot.length > 0 && next.pathFromRoot[1].url[0].path.indexOf('portale-accessi') > -1) {
      return this.router.parseUrl('/portale-accessi/login');
    } else {
      return this.router.parseUrl('/app/login');
    }
    // this.router.navigate(['/app/login']);
    return false;
  }
}
